import ReactDOM from "react-dom";
import { useEffect } from "react";
import Screener from "./Screener";
import { DialogFooter } from "./Footer";
import { DialogHeader, AlertDialogHeader } from "./Header";
import Button from "./Button";
import useModal from "../hooks/useModal";
import useLang from "../hooks/useLang";
import styles from "./modal.module.css";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../app/modalSlice";

const IGNORE_OUTSIDE_CLICK = true;

const Modal = ({ children, isOpen, onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOpen) dispatch(openModal());
    else dispatch(closeModal());
    // eslint-disable-next-line
  }, [isOpen]);

  const handleClick = (e) => {
    if (typeof onClose === "function") onClose();
  };
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <Screener onClick={IGNORE_OUTSIDE_CLICK ? undefined : handleClick} />
      <div className={styles.modal}>{children}</div>
    </>,
    document.getElementById("modal")
  );
};

const AlertModal = ({ children, isOpen, onClose, title }) => {
  const lang = useLang();
  const handleClick = (e) => {
    if (typeof onClose === "function") onClose();
  };
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <Screener onClick={IGNORE_OUTSIDE_CLICK ? undefined : handleClick} />
      <div className={styles.alertModal}>
        <AlertDialogHeader>
          <h2>{title}</h2>
        </AlertDialogHeader>
        {children}
        <DialogFooter>
          <Button onClick={onClose}>{lang("common.close")}</Button>
        </DialogFooter>
      </div>
    </>,
    document.getElementById("alertModal")
  );
};

const AlertModalWithOverride = ({
  children,
  isOpen,
  onClose,
  onOverride,
  title,
}) => {
  const lang = useLang();
  const handleClick = (e) => {
    if (typeof onClose === "function") onClose();
  };
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <Screener onClick={IGNORE_OUTSIDE_CLICK ? undefined : handleClick} />
      <div className={styles.alertModal}>
        <AlertDialogHeader title={title} />
        {children}
        <DialogFooter>
          <Button onClick={onClose}>{lang("common.close")}</Button>
          <Button onClick={onOverride} alert>
            {lang("common.ok")}
          </Button>
        </DialogFooter>
      </div>
    </>,
    document.getElementById("alertModal")
  );
};

const ModalHeader = ({ title }) => {
  if (title == null) return null;
  return <DialogHeader title={title} />;
};

const AlertModalHeader = ({ title }) => {
  if (title == null) return null;
  return <AlertDialogHeader title={title} />;
};

const OkCancelFooter = ({ onSubmit, onCancel }) => {
  const lang = useLang();
  return (
    <DialogFooter>
      <Button onClick={onCancel}>{lang("common.cancel")}</Button>
      <Button onClick={onSubmit} important>
        {lang("common.ok")}
      </Button>
    </DialogFooter>
  );
};

const SubmitCancelFooter = ({
  onSubmit,
  onCancel,
  submitLabel,
  cancelLabel,
  isProcessing,
  disabled,
}) => {
  const lang = useLang();
  return (
    <DialogFooter>
      <Button onClick={onCancel}>
        {cancelLabel ? cancelLabel : lang("common.cancel")}
      </Button>
      <Button onClick={onSubmit} important disabled={isProcessing || disabled}>
        {submitLabel ? submitLabel : lang("common.submit")}
      </Button>
    </DialogFooter>
  );
};
/*
const SubmitFooter = ({ onSubmit, submitLabel, isProcessing }) => {
  const lang = useLang();
  return (
    <DialogFooter>
      <Button onClick={onSubmit} important disabled={isProcessing}>
        {submitLabel ? submitLabel : lang("common.submit")}
      </Button>
    </DialogFooter>
  );
};
*/
const AlertOkCancelFooter = ({ onSubmit, onCancel, isLoading }) => {
  const lang = useLang();
  return (
    <DialogFooter>
      <Button onClick={onCancel} disabled={isLoading}>
        {lang("common.cancel")}
      </Button>
      <Button onClick={onSubmit} disabled={isLoading} alert>
        {lang("common.ok")}
      </Button>
    </DialogFooter>
  );
};

const ModalOpener = ({
  children,
  renderOpen,
  renderFooter,
  onCancel,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useModal({ onCancel });
  const props = { isOpen, onClose, ...rest };
  return (
    <>
      {typeof renderOpen === "function"
        ? renderOpen((event) => {
            if (typeof event?.preventDefault === "function")
              event?.preventDefault();
            onOpen();
          })
        : null}
      <Modal {...props}>
        {children}
        {typeof renderFooter === "function" ? renderFooter(onClose) : null}
      </Modal>
    </>
  );
};

const FormModal = ({
  children,
  title,
  submitLabel,
  cancelLabel,
  onSubmit,
  onCancel,
  renderOpen,
  isProcessing,
  disabled,
  ...rest
}) => {
  return (
    <>
      <ModalOpener
        renderOpen={renderOpen}
        onCancel={onCancel}
        {...rest}
        renderFooter={(onClose) => (
          <SubmitCancelFooter
            onSubmit={() => {
              if (typeof onSubmit === "function") onSubmit();
              if (typeof onClose === "function") onClose();
            }}
            onCancel={onClose}
            submitLabel={submitLabel}
            cancelLabel={cancelLabel}
            isProcessing={isProcessing}
            disabled={disabled}
          />
        )}
      >
        <ModalHeader title={title} />
        {children}
      </ModalOpener>
    </>
  );
};

const FormModalControlled = ({
  children,
  title,
  onSubmit,
  onCancel,
  onClose,
  submitLabel,
  cancelLabel,
  disabled,
  ...rest
}) => {
  return (
    <>
      <Modal {...rest}>
        <ModalHeader title={title} />
        {children}
        <SubmitCancelFooter
          onSubmit={() => {
            onSubmit();
            if (typeof onClose === "function") onClose();
          }}
          onCancel={onCancel}
          submitLabel={submitLabel}
          cancelLabel={cancelLabel}
          disabled={disabled}
        />
      </Modal>
    </>
  );
};

const ConfirmationModal = ({
  children,
  title,
  onSubmit,
  renderOpen,
  ...rest
}) => {
  return (
    <>
      <ModalOpener
        renderOpen={renderOpen}
        {...rest}
        renderFooter={(onCancel) => (
          <OkCancelFooter
            onSubmit={() => {
              onSubmit();
              onCancel();
            }}
            onCancel={onCancel}
          />
        )}
      >
        <ModalHeader title={title} />
        {children}
      </ModalOpener>
    </>
  );
};

const ConfirmationModalControlled = ({
  children,
  title,
  onSubmit,
  onCancel,
  ...rest
}) => {
  return (
    <>
      <Modal {...rest}>
        <ModalHeader title={title} />
        {children}
        <OkCancelFooter
          onSubmit={() => {
            onSubmit();
            onCancel();
          }}
          onCancel={onCancel}
        />
      </Modal>
    </>
  );
};

const AlertConfirmationModal = ({
  children,
  title,
  onSubmit,
  renderOpen,
  isLoading,
  ...rest
}) => {
  const lang = useLang();
  return (
    <>
      <ModalOpener
        renderOpen={renderOpen}
        {...rest}
        renderFooter={(onCancel) => (
          <AlertOkCancelFooter
            onSubmit={onSubmit}
            onCancel={onCancel}
            isLoading={isLoading}
          />
        )}
      >
        <AlertModalHeader title={title} />
        {isLoading ? <p>{lang("common.loading")}</p> : <> {children}</>}
      </ModalOpener>
    </>
  );
};

export {
  AlertModal,
  AlertModalWithOverride,
  ConfirmationModal,
  ConfirmationModalControlled,
  AlertConfirmationModal,
  FormModal,
  FormModalControlled,
};
export default Modal;
