import useTableRowConfig from "../hooks/useTableRowConfig";
import TableCell from "./TableCell";
import styles from "./table.module.css";

const TableRow = ({ config, data, ...rest }) => {
  const { tableRowConfig } = useTableRowConfig(config);

  return (
    <tr className={styles.tableRow} {...rest}>
      {tableRowConfig.map((cellConfig) => (
        <TableCell key={cellConfig.id} config={cellConfig} data={data} />
      ))}
    </tr>
  );
};

export default TableRow;
