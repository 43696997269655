import useNavigation from "./hooks/useNavigation";
import Button from "../../components/Button";
import classNames from "classnames";
import styles from "./sideNav.module.css";

const SideNav = ({ expanded }) => {
  const { links } = useNavigation();
  return (
    <div className={styles.sideNav}>
      {expanded ? (
        <img
          key="logotype"
          src={"/logotype.png"}
          alt={"logo"}
          className={styles.logo}
        />
      ) : (
        <img
          key="logo"
          src={"/logo.png"}
          alt={"logo"}
          className={classNames(styles.logo, { [styles.collapsed]: !expanded })}
        />
      )}

      <nav>
        {links.map((link) => (
          <Button
            sideNav
            pressed={link.selected}
            key={link.to}
            icon={link.icon}
            to={link.to}
          >
            {expanded ? link.caption : null}
          </Button>
        ))}
      </nav>
    </div>
  );
};

export default SideNav;
