import { useEffect } from "react";
import useTextFilter from "../../../hooks/useTextFilter";
import useLang from "../../../hooks/useLang";
import useConclusions from "./useConclusions";
import useModifierModal from "../../forms/hooks/useModifierModal";
import useConclusionBlock from "./useConclusionBlock";
import { usePostConclusionMutation } from "../conclusionsSlice";
import useHasAccess from "../../../hooks/useHasAccess";
// import useSorting from "../../../hooks/useSorting";
// import useFiltering from "../../../hooks/useFiltering";

const useConclusionsList = () => {
  const hasAccess = useHasAccess({ apiCall: "conclusions.GET" });
  const lang = useLang();
  const conclusions = useConclusions();
  const { filteredData, ...textFilterAttribs } = useTextFilter({
    data: conclusions?.data,
    id: ["name", "level1", "level2", "level3"],
    label: lang("common.search"),
  });
  const { formAttribs, formActions } = useModifierModal({
    mutationHook: usePostConclusionMutation,
    formConfigHook: useConclusionBlock,
    navigateOnSuccess: (response) =>
      `/conclusions/${response?.data?.conclusion_id}`,
  });
  // const filterParams = useFiltering("level1", ["Visi", "ABC", "DEF", "GHI"]);

  useEffect(() => {
    conclusions?.refetch();
    // eslint-disable-next-line
  }, []);

  const page = {
    hasAccess,
    header: {
      title: conclusions.isLoading
        ? lang("conclusions.conclusions_loading")
        : lang("conclusions.title"),
      buttons: {
        caption: lang("common.create"),
        icon: "plus",
        title: lang("conclusions.create"),
        type: "modifier-modal",
        mutation: "conclusions.POST",
        formAttribs,
        ...formActions,
      },
    },
    paging: { ...conclusions.pagination },
    items: [
      {
        id: "conclusionsTable",
        type: "table",
        textFilter: textFilterAttribs,
        data: filteredData,
        config: [
          {
            id: "name",
            /* header: nameSortParams, */
            /* sort: true, */
            width: 72,
            link: (rowData) => rowData.id,
            warning: (rowData) =>
              !(Array.isArray(rowData.features) && rowData.features.length > 0),
            isLoading: (rowData) => rowData.id.includes("tmp."),
          },
          {
            id: "level1",
            width: 192,
            grow: true,
            /* header: filterParams, */
            /* filter: true, */
          },
        ],
      },
    ],
  };

  return page;
};

export default useConclusionsList;
