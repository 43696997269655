const ADMIN = "ADMIN";
const EXPERT = "EXPERT";
const REGISTERED = [ADMIN, EXPERT];

const accessConfig = {
  routes: {
    features: REGISTERED,
    conclusions: REGISTERED,
    users: REGISTERED,
  },
  apiCalls: {
    "features.GET": REGISTERED,
    "features.POST": REGISTERED,
    "features.id.GET": REGISTERED,
    "features.id.PATCH": REGISTERED,
    "features.id.DELETE": REGISTERED,
    "features.id.conclusions.POST": REGISTERED,
    "conclusions.GET": REGISTERED,
    "conclusions.POST": REGISTERED,
    "conclusions.id.GET": REGISTERED,
    "conclusions.id.PATCH": REGISTERED,
    "conclusions.id.DELETE": REGISTERED,
    "conclusions.id.features.POST": REGISTERED,
    "users.GET": REGISTERED,
    "users.POST": REGISTERED,
  },
};

export default accessConfig;
