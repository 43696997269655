import P from "../../components/Paragraph";
import Button from "../../components/Button";
import Input from "./Input";
import TextArea from "./TextArea";
import ChipSelector from "./ChipSelector";
import DropdownSelector from "./DropdownSelector";
import ListSelector from "./ListSelector";
import classNames from "classnames";
import useLang from "../../hooks/useLang";
import useFormInputText from "./hooks/useFormInputText";
import useFormSelector from "./hooks/useFormSelector";
import useFormMultiselector from "./hooks/useFormMultiselector";
import useFormListSelector from "./hooks/useFormListSelector";
import useFormMatrixSelector from "./hooks/useFormMatrixSelector";
import styles from "./input.module.css";
/*
Current input types:
button, checkbox, color, date, datetime-local, email, file, hidden, image, month, number, password, radio,range, reset, search, submit, tel, text,time, url, week
Default input type: text

Other form elements:
<textarea/>
<select><option/></select>
*/

const FormConstantText = ({ value, inputWidth, ...rest }) => {
  return (
    <div
      className={classNames(styles.constantText, styles[inputWidth])}
      {...rest}
    >
      <P {...rest}>{value}</P>
    </div>
  );
};

const FormInputText = ({ id, value: initialValue, onChange, ...rest }) => {
  const inputAttributes = useFormInputText({ id, initialValue, onChange });
  return <Input {...inputAttributes} {...rest} />;
};

const FormTextArea = ({ id, value: initialValue, onChange, ...rest }) => {
  const inputAttributes = useFormInputText({ id, initialValue, onChange });
  return <TextArea {...inputAttributes} {...rest} />;
};

const FormChipRadio = ({
  id,
  value: initialValue,
  options,
  onChange,
  idAsValue,
  ...rest
}) => {
  const selectorAttributes = useFormSelector({
    id,
    initialValue,
    options,
    onChange,
    idAsValue,
  });
  return <ChipSelector {...selectorAttributes} {...rest} />;
};

const FormChipMultiselector = ({
  id,
  value: initialValue,
  options,
  onChange,
  ...rest
}) => {
  const selectorAttributes = useFormMultiselector({
    id,
    initialValue,
    options,
    onChange,
  });
  return <ChipSelector {...selectorAttributes} {...rest} />;
};

const FormListSelector = ({
  id,
  value: initialValue,
  options,
  onChange,
  link,
  ...rest
}) => {
  const selectorAttributes = useFormListSelector({
    id,
    initialValue,
    options,
    onChange,
  });
  return <ListSelector {...selectorAttributes} {...rest} />;
};

const FormMatrixSelector = ({
  id,
  valueData,
  value: initialValue,
  options,
  onChange,
  link,
  inputs,
  ...rest
}) => {
  const { handleAdd, handleRemove, inputGroups } = useFormMatrixSelector({
    id,
    valueData,
    value: initialValue,
    options,
    onChange,
    link,
    inputs,
    ...rest,
  });
  const lang = useLang();
  return (
    <>
      {inputGroups?.map((dataItem, dataItemIdx) => (
        <div
          key={`${dataItemIdx}_${JSON.stringify(dataItem)}`}
          style={{ display: "flex", gap: "var(--gap-base)" }}
        >
          {inputs.map((inputItem) => {
            return (
              <FormInput
                key={inputItem.id || JSON.stringify(inputItem)}
                value={dataItem[inputItem.id]}
                {...inputItem}
                id={`${dataItemIdx}___${inputItem.id}`}
                onChange={inputItem.id != null ? onChange : () => {}}
                noLabel
              />
            );
          })}
          <div style={{ display: "flex", flexGrow: "1" }} />
          <Button
            icon="trashIcon"
            inline
            onClick={(event) => {
              event.preventDefault();
              handleRemove({ idx: dataItemIdx });
            }}
          />
        </div>
      ))}
      <Button
        onClick={(event) => {
          event.preventDefault();
          handleAdd();
        }}
      >
        {lang("common.add")}
      </Button>
    </>
  );
};

const FormDropdownSelector = ({
  id,
  idAsValue,
  value: initialValue,
  options,
  onChange,
  ...rest
}) => {
  const selectorAttributes = useFormSelector({
    id,
    idAsValue,
    initialValue,
    options,
    onChange,
  });
  return <DropdownSelector {...selectorAttributes} {...rest} />;
};

const FormInput = ({ type, formOnly, formData, ...rest }) => {
  if (type === "label") return <FormConstantText {...rest} />;
  if (type === "textarea") return <FormTextArea {...rest} />;
  if (type === "chip-radio") return <FormChipRadio {...rest} />;
  if (type === "chip-multiselector") return <FormChipMultiselector {...rest} />;
  if (type === "dropdown-selector") return <FormDropdownSelector {...rest} />;
  if (type === "list") return <FormListSelector {...rest} />;
  if (type === "matrix")
    return <FormMatrixSelector formData={formData} {...rest} />;

  return <FormInputText type={type} {...rest} />;
};

export default FormInput;
