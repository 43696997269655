import FormInput from "../features/forms/FormInput";
import styles from "./textFilter.module.css";

const TextFilter = (textFilterAttributes) => {
  return (
    <div className={styles.textFilter}>
      <FormInput {...textFilterAttributes} />
    </div>
  );
};

export default TextFilter;
