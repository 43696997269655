import accessConfig from "../config/accessConfig";

const userRoles = ["ADMIN"];

const hasRequiredRoles = (requiredRoles, userRoles) => {
  let result = false;
  if (requiredRoles == null) return true;
  if (Array.isArray(userRoles) && Array.isArray(requiredRoles)) {
    const rolesWithAccess = userRoles.filter((userRole) =>
      requiredRoles.indexOf(userRole > -1)
    );
    if (rolesWithAccess.length > 0) result = true;
  }
  return result;
};

const hasAccessToLink = (link) => {
  let result = false;
  if (accessConfig.routes[link] != null) {
    result = hasRequiredRoles(accessConfig.routes[link], userRoles);
  } else {
    const linkPathArray = link.split("/");
    if (linkPathArray[0] === "") {
      result = hasRequiredRoles(
        accessConfig.routes[linkPathArray[1]],
        userRoles
      );
    } else {
      result = true;
    }
  }
  return result;
};

const hasAccessToApi = (apiCall) => {
  let result = false;
  if (accessConfig.apiCalls[apiCall] != null) {
    result = hasRequiredRoles(accessConfig.apiCalls[apiCall], userRoles);
  } else {
    console.error(apiCall, "not found in accessConfig");
    result = false;
  }
  return result;
};

const useHasAccess = ({ link, apiCall }) => {
  if (link != null) return hasAccessToLink(link);
  if (apiCall != null) return hasAccessToApi(apiCall);
  return true;
};

export default useHasAccess;
