import { ConfirmationModalControlled } from "../../components/Modal";
import P from "../../components/Paragraph";
import useModal from "../../hooks/useModal";

const ConfirmationModal = ({
  renderOpen,
  onSubmit: _onSubmit,
  onCancel: _onCancel,
  onClose: _onClose,
  confirmationText,
}) => {
  const { onOpen, ...rest } = useModal({
    onSubmit: _onSubmit,
    onCancel: _onCancel,
    onClose: _onClose,
  });
  return (
    <>
      {renderOpen(() => {
        onOpen();
      })}
      <ConfirmationModalControlled {...rest}>
        <P>{confirmationText}</P>
      </ConfirmationModalControlled>
    </>
  );
};

export default ConfirmationModal;
