import Button from "./Button";
import styles from "./list.module.css";

const ListItem = ({ title, data, link, ...rest }) => {
  return (
    <li {...rest}>
      {typeof link === "function" ? (
        <Button inline navigation unpadded to={link(data)}>
          {title}
        </Button>
      ) : (
        title
      )}
    </li>
  );
};

const List = ({
  id,
  data,
  dataDisplay,
  link,
  options,
  valueData,
  inputs,
  ...rest
}) => {
  return (
    <ul className={styles.list} {...rest}>
      {data &&
        data.map((dataItem, idx) => (
          <ListItem
            key={dataItem?.id || idx}
            title={dataItem[id]}
            data={dataItem}
            link={link}
          />
        ))}
    </ul>
  );
};

export default List;
