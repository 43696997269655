import useLang from "../hooks/useLang";
import Label from "./Label";
import P from "./Paragraph";
import List from "./List";
import Table from "./Table";
import styles from "./sectionItem.module.css";

const TextItem = ({ value, ...rest }) => {
  return <P {...rest}>{value}</P>;
};

const ItemWithLabel = ({ id, value, displayValue, ...rest }) => {
  const lang = useLang();
  return (
    <div className={styles.row} {...rest}>
      <Label>{lang(`captions.${id}`)}</Label>
      <TextItem value={displayValue || value} />
    </div>
  );
};

const SectionItem = ({ type, formOnly, required, idAsValue, ...rest }) => {
  if (formOnly === true) return null;
  if ((type == null || type === "textarea") && rest?.id == null)
    return <TextItem {...rest} />;
  if ((type == null || type === "textarea") && rest?.id != null)
    return <ItemWithLabel {...rest} />;
  if (type === "list" || type === "matrix") return <List {...rest} />;
  if (type === "table") return <Table {...rest} />;
  return <ItemWithLabel {...rest} />;
};

export default SectionItem;
