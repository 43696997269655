const lang = {
  LT: {
    common: {
      ok: "Gerai",
      cancel: "Atšaukti",
      yes: "Taip",
      no: "Ne",
      submit: "Pateikti",
      close: "Uždaryti",
      dismiss: "Atmesti",
      error: "Klaida",
      warning: "Įspėjimas",
      caution: "Dėmesio",
      add: "Pridėti",
      create: "Sukurti",
      delete: "Ištrinti",
      edit: "Redaguoti",
      loading: "Kraunama...",
      searching: "Ieškoma...",
      preparing: "Ruošiama...",
      uploading: "Įkeliama...",
      processing: "Apdorojama...",
      being_replaced: "Perrašoma...",
      no_matches_found: "Nerasta atitikmenų",
      created_by: "Sukūrė",
      search: "Ieškoti",
      no_access: "Vartotojas neturi priėjimo prie šių duomenų.",
      last_edit: "Paskutinį kartą keitė",
      today: "Šiandien",
      yesterday: "Vakar",
      age: "Amžius",
    },
    captions: {
      description: "Aprašymas",
      given_name: "Vardas",
      family_name: "Pavardė",
      full_name: "Vardas Pavardė",
      primary_email: "El. paštas",
      name: "Pavadinimas",
      roles: "Rolės",
      level1: "Lygis 1",
      level2: "Lygis 2",
      level3: "Lygis 3",
      destination: "Paskirtis",
      aspect: "Aspektas",
    },
    conclusions: {
      title: "Išvados",
      conclusions_loading: "Išvados kraunasi...",
      conclusion_loading: "Išvada kraunasi...",
      create: "Sukurti išvadą",
      edit_features_list: "Keisti ypatybių sąrašą",
      edit_conclusion: "Redaguoti išvadą",
      edit_rules: "Redaguoti taisykles",
      descriptions: "Išvados tekstas",
      rules: "Taisyklės",
      destination: "Išvados paskirtis",
      generic: "Bendras",
      neurological: "Neurologinis",
      physiological: "Fiziologinis",
      pedagogical: "Pedagoginis",
      psychological: "Psicholiginis",
    },
    features: {
      title: "Ypatybės",
      features_loading: "Ypatybės kraunasi...",
      feature_loading: "Ypatybė kraunasi...",
      create: "Sukurti ypatybę",
      edit_feature: "Redaguoti ypatybę",
      edit_conclusions_list: "Keisti išvadų sąrašą",
    },
    users: {
      title: "Vartotojai",
      users_loading: "Vartotojai kraunasi...",
      create: "Sukurti vartotoją",
    },
    auth: {
      login: "Prisijungti",
      logout: "Atsijungti",
      username: "Vartotojo vardas",
      password: "Slaptažodis",
    },
  },
};

const getTranslation = ({ translations, stringArray }) => {
  if (translations == null) return null;
  if (Array.isArray(stringArray)) {
    if (stringArray.length > 1) {
      return getTranslation({
        translations: translations[stringArray[0].toLocaleLowerCase()],
        stringArray: stringArray.slice(1),
      });
    } else if (stringArray.length === 1) {
      return translations[stringArray[0].toLocaleLowerCase()];
    } else {
      return null;
    }
  }
  return null;
};

const useLang = () => {
  const onTranslate = (textToTranslate) => {
    if (typeof textToTranslate !== "string") return "";
    const result = getTranslation({
      translations: lang.LT,
      stringArray: textToTranslate.split("."),
    });
    if (result == null) return textToTranslate;
    return result;
  };
  return onTranslate;
};

export default useLang;
