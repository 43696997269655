import useLang from "../hooks/useLang";
import { PageHeader } from "./Header";
import Article from "./Article";
import Paging from "./Paging";
import styles from "./page.module.css";

const Page = ({ header, articles, items, paging, hasAccess, ...rest }) => {
  const lang = useLang();
  if (hasAccess === false) {
    return (
      <div className={styles.page} {...rest}>
        {lang("common.no_access")}
      </div>
    );
  }
  return (
    <div className={styles.page} {...rest}>
      {header ? <PageHeader {...header} /> : null}
      {paging && <Paging {...paging} />}
      {items && <Article items={items} />}
      {articles ? (
        Array.isArray(articles) ? (
          articles?.map((article, idx) => (
            <Article key={article?.id || idx} {...article} />
          ))
        ) : (
          <Article key={articles?.id} {...articles} />
        )
      ) : null}
    </div>
  );
};

export default Page;
