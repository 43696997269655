import { P2 } from "./Paragraph";
import useLang from "../hooks/useLang";
import useSimpleDate from "../hooks/useSimpleDate";
import UserBadge from "./UserBadge";
import styles from "./lastEdit.module.css";

const LastEdit = ({ date, user_id, ...rest }) => {
  const lang = useLang();
  const { getSimpleDate } = useSimpleDate();
  return (
    <div className={styles.lastEdit} {...rest}>
      <P2>{`${lang("common.last_edit")}:`}</P2>
      <UserBadge userId={user_id} />
      <P2>{getSimpleDate(date)}</P2>
    </div>
  );
};

export default LastEdit;
