import { P2 } from "./Paragraph";
import UserRoundel from "./UserRoundel";
import useUser from "../features/users/hooks/useUser";
import styles from "./userBadge.module.css";

const UserBadge = ({ userId }) => {
  const user = useUser(userId);
  if (user?.data == null) return null;
  return (
    <div className={styles.userBadge}>
      <UserRoundel picture={user?.data?.picture} />
      <P2>{`${user?.data?.given_name} ${user?.data?.family_name}`}</P2>
    </div>
  );
};

export default UserBadge;
