import Button from "./Button";
import classNames from "classnames";
import styles from "./table.module.css";

const TableCell = ({ config, data, headerCell, ...rest }) => {
  const caption = data[config?.id];
  let isLoading;
  if (typeof config?.isLoading === "function") {
    isLoading = config.isLoading(data);
  }
  let hasWarning;
  if (typeof config?.warning === "function") {
    hasWarning = config.warning(data);
  }
  const captionWithWarning = `${caption}${hasWarning ? " ⚠" : ""}`;

  let content = captionWithWarning;
  if (typeof config.link === "function") {
    content = (
      <Button
        inline
        navigation
        unpadded
        loader={isLoading}
        to={config.link(data)}
      >
        {captionWithWarning}
      </Button>
    );
  }
  if (typeof config.linkList === "function") {
    const links = config.linkList(data);
    if (links && Array.isArray(links)) {
      content = (
        <div className={styles.cellContainer}>
          {links.map((link) => (
            <Button key={link.id} inline unpadded to={link.to}>
              {link.caption}
            </Button>
          ))}
        </div>
      );
    }
  }
  
  return (
    <td
      key={config.id}
      className={classNames(styles.cell)}
      style={{
        width: `var(--size-${config.width})`,
        minWidth: `var(--size-${config.width})`,
        flexGrow: config?.grow === true ? 1 : 0,
      }}
      {...rest}
    >
      {content}
    </td>
  );
};

export default TableCell;
