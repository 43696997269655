import { useEffect } from "react";
import useMultiSelector from "../../../hooks/useMultiSelector";
import useLang from "../../../hooks/useLang";

const useFormMultiselector = ({
  id,
  initialValue,
  options,
  onChange: handleChange,
  idAsValue,
}) => {
  const lang = useLang();

  const getOptionWithIdAndLabel = (option, idx) => {
    if (typeof option === "object" && idAsValue ? option.id : option.label) {
      const result = {
        id:
          option.id ||
          `${option.slice(0, Math.min(3, option.label.length))}_${idx}`,
        label: option.label || option.id,
      };
      return result;
    } else if (typeof option === "string") {
      return {
        id: idAsValue
          ? option
          : `${option.slice(0, Math.min(3, option.length))}_${idx}`,
        label: option,
      };
    } else {
      return { id: idAsValue ? option : idx, label: option };
    }
  };

  const getSelectorOptions = (options) => {
    let selectorOptions = [];
    if (Array.isArray(options)) {
      selectorOptions = options.map((option, idx) => {
        return getOptionWithIdAndLabel(option, idx);
      });
    }
    return selectorOptions;
  };

  const selector = useMultiSelector({ options: getSelectorOptions(options) });

  useEffect(() => {
    if (options && options?.length > 0) {
      selector.reset(getSelectorOptions(options), selector.value);
    }
    // eslint-disable-next-line
  }, [options && options?.length]);

  useEffect(() => {
    if (initialValue != null && options?.length > 1) {
      const initialValueArray = Array.isArray(initialValue)
        ? initialValue
        : [initialValue];
      const selectorOptions = getSelectorOptions(options);
      const initialValuesWithIndices = initialValueArray.map((initialValue) => {
        const idx = selectorOptions.findIndex((option) => {
          if (typeof initialValue === "string")
            return option.label === initialValue;
          if (initialValue.id && option.id)
            return option.id === initialValue.id;
          if (initialValue.label && option.label)
            return option.label === initialValue.label;
          return false;
        });
        return idx > -1 ? getOptionWithIdAndLabel(initialValue, idx) : null;
      });
      selector.setValue(
        initialValuesWithIndices.filter((value) => value != null)
      );
    }
    // eslint-disable-next-line
  }, [JSON.stringify(initialValue), options && options?.length]);

  useEffect(() => {
    if (typeof handleChange === "function") {
      handleChange({
        id: id,
        value: selector.value.map((value) =>
          idAsValue ? value.id : value.label
        ),
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(selector.value)]);

  return {
    key: id,
    label: lang(`captions.${id}`),
    type: "text",
    value: selector.value,
    options: selector.options,
    onChange: selector.onChange,
    ...selector.attributes,
  };
};

export default useFormMultiselector;
