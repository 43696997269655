import useFormData from "../../forms/hooks/useFormData";
import { useNavigate } from "react-router-dom";

const useModifierModal = ({
  mutationHook,
  formConfigHook,
  navigateOnSuccess,
  params,
}) => {
  const navigate = useNavigate();
  const formConfig = formConfigHook();

  let formFields = [];
  let inputs = [];
  if (Array.isArray(formConfig)) {
    inputs = formConfig;
  } else if (formConfig.sections) {
    if (Array.isArray(formConfig.sections)) {
      formConfig.sections.map((section) => {
        if (Array.isArray(section.items)) {
          inputs = [...inputs, ...section.items];
          formFields.push({ header: section.header, formRows: section.items });
        }
        return false;
      });
    } else {
      if (Array.isArray(formConfig.sections.items)) {
        inputs = formConfig.sections.items;
        formFields.push({
          header: formConfig.sections.header,
          formRows: formConfig.sections.items,
        });
      }
    }
  }

  const { onChange, formData, missingInput } = useFormData(inputs);
  const [mutation, mutationProps] = mutationHook();
  const isLoading = mutationProps?.isLoading || false;
  const formRows = inputs;

  const onSubmit = async () => {
    try {
      const response = await mutation({ body: formData, params });
      if (typeof navigateOnSuccess === "function") {
        const destination = navigateOnSuccess(response);
        if (destination != null) {
          navigate(destination);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    formAttribs: {
      formData,
      onChange,
      formRows,
      formFields,
    },
    formActions: {
      onSubmit,
      isLoading,
      disabled: missingInput != null,
    },
  };
};

export default useModifierModal;
