import { useNavigate } from "react-router-dom";

const useConfirmationModal = ({ mutationHook, navigateOnSuccess, params }) => {
  const navigate = useNavigate();
  const [mutation] = mutationHook();

  const onSubmit = async () => {
    try {
      await mutation({ params });
      if (typeof navigateOnSuccess === "function") {
        const destination = navigateOnSuccess();
        if (destination != null) {
          navigate(destination);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    formActions: {
      onSubmit,
    },
  };
};

export default useConfirmationModal;
