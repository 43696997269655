import { apiSlice } from "../api/apiSlice";

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `/users`,
      providesTags: [{ type: "User" }],
    }),
    postUser: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/users`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        dispatch(
          apiSlice.util.updateQueryData("getUsers", undefined, (draft) => {
            draft.push({ ...body, id: `tmp.${Date.now()}` });
          })
        );
        try {
          await queryFulfilled;
        } catch (err) {
          apiSlice.util.invalidateTags([{ type: "User" }]);
        }
      },
      invalidatesTags: [{ type: "Feature" }],
    }),
  }),
});

export const { useGetUsersQuery, usePostUserMutation } = usersSlice;
