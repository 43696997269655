export const H1 = ({ children, ...rest }) => <h1 {...rest}>{children}</h1>;
export const H2 = ({ children, ...rest }) => <h2 {...rest}>{children}</h2>;
export const H3 = ({ children, ...rest }) => <h3 {...rest}>{children}</h3>;
export const H4 = ({ children, ...rest }) => <h4 {...rest}>{children}</h4>;

export const PageHeading = ({ children, ...rest }) => (
  <H1 {...rest}>{children}</H1>
);
export const ArticleHeading = ({ children, ...rest }) => (
  <H4 {...rest}>{children}</H4>
);
export const DialogHeading = ({ children, ...rest }) => (
  <H2 {...rest}>{children}</H2>
);
export const SectionHeading = ({ children, ...rest }) => (
  <H4 {...rest}>{children}</H4>
);
