import Button from "./Button";
import usePaging from "../hooks/usePaging";
import styles from "./paging.module.css";

const Paging = (props) => {
  const { pageNumbers, pageLinks, activePage } = usePaging(props);

  if (pageNumbers < 2) return null;

  return (
    <div className={styles.paging}>
      {pageNumbers &&
        pageNumbers.map((pageNumber) => (
          <Button
            inline={
              activePage && pageNumber.toString() !== activePage.toString()
            }
            key={pageNumber}
            to={`?${pageLinks[pageNumber]}`}
          >
            {pageNumber}
          </Button>
        ))}
    </div>
  );
};

export default Paging;
