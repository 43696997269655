import useLang from "../hooks/useLang";
import TableHeaderButton from "./TableHeaderButton";
import classNames from "classnames";
import styles from "./table.module.css";

const TableHeaderCell = ({ config, ...rest }) => {
  const lang = useLang();
  return (
    <th
      className={classNames(styles.cell, styles.header)}
      style={{
        width: `var(--size-${config.width})`,
        minWidth: `var(--size-${config.width})`,
        flexGrow: config?.grow === true ? 1 : 0,
        overflow: "unset",
      }}
      key={config.id}
      {...rest}
    >
      <div className={styles.headerCellContainer}>
        {config?.header?.title || lang(`captions.${config.id}`)}
        {config.sort ? <TableHeaderButton sort {...config.header} /> : null}
        {config.filter ? <TableHeaderButton filter {...config.header} /> : null}
      </div>
    </th>
  );
};

export default TableHeaderCell;
