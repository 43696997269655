import {
  PageHeading,
  ArticleHeading,
  SectionHeading,
  DialogHeading,
} from "./Heading";
import { PageHeaderButtons, ArticleHeaderButtons } from "./HeaderButtons";
import LastEdit from "./LastEdit";
import styles from "./header.module.css";

export const PageHeader = ({ title, buttons, lastEdit, ...rest }) => {
  return (
    <header className={styles.pageHeader} {...rest}>
      <div className={styles.titleContainer}>
        <PageHeading>{title}</PageHeading>
        <PageHeaderButtons buttons={buttons} />
      </div>
      {lastEdit != null ? <LastEdit {...lastEdit} /> : null}
    </header>
  );
};

export const ArticleHeader = ({ title, buttons, ...rest }) => {
  return (
    <header className={styles.articleHeader} {...rest}>
      <div className={styles.titleContainer}>
        <ArticleHeading>{title}</ArticleHeading>
        <ArticleHeaderButtons buttons={buttons} />
      </div>
    </header>
  );
};

export const SectionHeader = ({ title, ...rest }) => {
  return (
    <header className={styles.sectionHeader} {...rest}>
      <div className={styles.titleContainer}>
        <SectionHeading>{title}</SectionHeading>
      </div>
    </header>
  );
};

export const DialogHeader = ({ title, ...rest }) => {
  return (
    <header className={styles.dialogHeader} {...rest}>
      <DialogHeading>{title}</DialogHeading>
    </header>
  );
};

export const AlertDialogHeader = ({ title, ...rest }) => {
  return (
    <header className={styles.alertDialogHeader} {...rest}>
      <DialogHeading>{title}</DialogHeading>
    </header>
  );
};
