import { useState } from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Aside from "./Aside";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectIsOpen } from "../app/modalSlice";
import styles from "./layout.module.css";

const Layout = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);
  const modalOpen = useSelector(selectIsOpen);
  return (
    <>
      <Aside
        expanded={sideNavExpanded}
        onToggle={() => {
          setSideNavExpanded((state) => !state);
        }}
      />
      <div
        className={classNames(styles.app, {
          [styles.sideNavExpanded]: sideNavExpanded,
          [styles.sideNavCollapsed]: !sideNavExpanded,
          [styles.modalOpen]: modalOpen,
        })}
      >
        <ErrorBoundary fallback="Error in content">
          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default Layout;
