import { SectionHeader } from "./Header";
import SectionItem from "./SectionItem";
import styles from "./section.module.css";

export const SectionContainer = ({ items, ...rest }) => {
  return (
    <div className={styles.sectionContainer} {...rest}>
      {items ? (
        Array.isArray(items) ? (
          items?.map((sectionItem) => (
            <SectionItem key={sectionItem?.id} {...sectionItem} />
          ))
        ) : (
          <SectionItem key={items?.id} {...items} />
        )
      ) : null}
    </div>
  );
};

const Section = ({ header, items, ...rest }) => {
  let itemArray = items;
  if (!Array.isArray(itemArray)) {
    if (items?.id) {
      itemArray = [items];
    } else {
      return null;
    }
  }
  const itemsToDisplay = itemArray.filter((item) => !item.formOnly);
  if (itemsToDisplay.length < 1) return null;
  return (
    <section {...rest}>
      {header ? <SectionHeader {...header} /> : null}
      <SectionContainer items={itemsToDisplay} />
    </section>
  );
};

export default Section;
