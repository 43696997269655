import usePaging from "../../../hooks/usePaging";
import { useLocation } from "react-router-dom";
import { useGetFeaturesQuery } from "../featuresSlice";

const useFeatures = () => {
  const location = useLocation();
  const { activePage } = usePaging({});
  const features = useGetFeaturesQuery({
    page: location.pathname === "/features" ? activePage : 1,
  });

  return {
    ...features,
    data: features?.data?.response,
    pagination: features?.data?.pagination,
  };
};

export default useFeatures;
