import { Routes, Route, Navigate } from "react-router-dom";
import PersistLogin from "./components/PersistLogin";
import Login from "./features/auth/Login";
import Cognito from "./features/auth/Cognito";
import Layout from "./components/Layout";
import FeaturesList from "./features/features/FeaturesList";
import FeaturePage from "./features/features/FeaturePage";
import ConclusionsList from "./features/conclusions/ConclusionsList";
import ConclusionPage from "./features/conclusions/ConclusionPage";
import UsersList from "./features/users/UsersList";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="cognito" element={<Cognito />} />
        <Route path="login" element={<Login />} />
        <Route element={<PersistLogin />}>
          <Route element={<Layout />}>
            <Route path="features">
              <Route index element={<FeaturesList />} />
              <Route path=":featureId" element={<FeaturePage />} />
            </Route>
            <Route path="conclusions">
              <Route index element={<ConclusionsList />} />
              <Route path=":conclusionId" element={<ConclusionPage />} />
            </Route>
            <Route path="users" element={<UsersList />} />
            <Route index element={<Navigate to={"features"} replace />} />
            <Route path="*" element={<Navigate to={"features"} replace />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
