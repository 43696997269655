import useLang from "../../../hooks/useLang";
import useFeatures from "../../features/hooks/useFeatures";
import useConclusions from "../../conclusions/hooks/useConclusions";
import useUsers from "../../users/hooks/useUsers";
import { useLocation } from "react-router-dom";

const routes = ["features", "conclusions", "users"];
const routeIcons = {
  features: "graph",
  conclusions: "list",
  users: "users",
};

const useNavigation = () => {
  useFeatures();
  useConclusions();
  useUsers();
  const location = useLocation();

  const lang = useLang();
  const links = routes.map((route) => ({
    to: route,
    caption: lang(`${route}.title`),
    icon: routeIcons[route],
    selected: location?.pathname === `/${route}`,
  }));
  return { links };
};

export default useNavigation;
