import { apiSlice } from "../api/apiSlice";
import getPaginationInfo from "../../utils/getPaginationInfo";
import paramConfig from "../../config/paramConfig";

export const conclusionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConclusions: builder.query({
      query: ({ page = 1, pageSize = 100, sortBy }) =>
        `/conclusions?${paramConfig.PAGE_NUMBER}=${page}&${
          paramConfig.PAGE_SIZE
        }=${pageSize}${sortBy ? paramConfig.SORT_BY + "=" + sortBy : ""}`,
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        return { response, pagination };
      },

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          data?.response?.map((conclusion) => {
            let exists = false;
            dispatch(
              apiSlice.util.updateQueryData(
                "getConclusion",
                { id: conclusion?.id },
                () => {
                  exists = true;
                }
              )
            );
            if (!exists) {
              dispatch(
                apiSlice.util.upsertQueryData(
                  "getConclusion",
                  { id: conclusion.id },
                  conclusion
                )
              );
            }
            return false;
          });
        } catch (err) {
          console.error(err);
        }
      },
      providesTags: (result, error, arg) => [
        { type: "Conclusion" },
        { type: "Conclusion", id: arg?.page },
        { type: "Conclusion", id: arg?.pageSize },
        { type: "Conclusion", id: arg?.sortBy || "unsorted" },
      ],
    }),
    getConclusion: builder.query({
      query: ({ id }) => `/conclusions/${id}`,
      providesTags: (result, error, arg) => [
        { type: "Conclusion", id: arg?.id },
      ],
    }),
    postConclusion: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/conclusions`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        dispatch(
          apiSlice.util.updateQueryData(
            "getConclusions",
            undefined,
            (draft) => {
              draft.push({ ...body, id: `tmp.${Date.now()}` });
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.upsertQueryData(
              "getConclusion",
              { id: data?.conclusion_id },
              body
            )
          );
        } catch (err) {
          apiSlice.util.invalidateTags([{ type: "Conclusion" }]);
        }
      },
      invalidatesTags: [{ type: "Conclusion" }],
    }),
    deleteConclusion: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/conclusions/${params?.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Conclusion" }],
    }),
    patchConclusion: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/conclusions/${params?.conclusionId}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Conclusion" },
        { type: "Conclusion", id: arg?.params?.conclusionId },
      ],
    }),
    postFeatureForConclusion: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/conclusions/${params?.conclusionId}/features`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.invalidateTags([
              { type: "Feature" },
              { type: "Feature", id: body?.feature_id },
            ])
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteFeatureForConclusion: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/conclusions/${params?.conclusionId}/features/${params?.featureId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ params }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.invalidateTags([
              { type: "Feature", id: params?.featureId },
            ])
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const {
  useGetConclusionsQuery,
  useGetConclusionQuery,
  usePostConclusionMutation,
  useDeleteConclusionMutation,
  usePatchConclusionMutation,
  usePostFeatureForConclusionMutation,
  useDeleteFeatureForConclusionMutation,
} = conclusionsSlice;
