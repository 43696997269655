import { useGetUsersQuery } from "../usersSlice";

const useUser = (userId) => {
  const users = useGetUsersQuery();
  let user = { ...users };
  if (Array.isArray(user.data)) {
    user.data = user.data.find((user) => user.id === userId);
  }
  return user;
};

export default useUser;
