import { useRef, useEffect } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useSearchParams from "../../../hooks/useSearchParams";
import { baseUrl } from "../../api/apiSlice";
import * as jose from "jose";
import { useNavigate } from "react-router";
import useAuth from "./useAuth";

import { useDispatch } from "react-redux";
import { setCredentials, selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";

const useCognito = () => {
  const navigate = useNavigate();
  const [origin] = useLocalStorage("origin") || "/";
  const [originSearch] = useLocalStorage("search");
  const token = useSelector(selectCurrentToken);

  const { refreshToken, setRefreshToken, getCallbackUrl, clientId } = useAuth();
  const authCodeRef = useRef();
  const { getParam } = useSearchParams();
  const authCode = getParam("code");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && refreshToken) {
      navigate(`${origin}${originSearch ? originSearch : ""}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, [token, refreshToken]);

  useEffect(() => {
    if (authCode == null) navigate("/");
    // eslint-disable-next-line
  }, [authCode]);

  const getTokens = async (authCode, redirectUrl, clientId) => {
    const apiUrl = `${baseUrl}/oauth2/token`;
    const queryParams = [
      "grant_type=authorization_code",
      `code=${authCode}`,
      `redirect_uri=${redirectUrl}`,
      `client_id=${clientId}`,
    ];
    try {
      fetch(`${apiUrl}?${queryParams.join("&")}`, {
        method: "POST",
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.refresh_token && data.id_token) {
            const decoded = jose.decodeJwt(data.id_token);
            setRefreshToken(data.refresh_token);
            dispatch(
              setCredentials({ token: data.id_token, uid: decoded.uid })
            );
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  if (authCode && authCode !== authCodeRef.current) {
    authCodeRef.current = authCode;
    getTokens(authCode, getCallbackUrl(), clientId);
  }

  return {};
};

export default useCognito;
