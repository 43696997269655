import Form from "../../components/Form";
import FieldSet from "./FieldSet";
import FormRow from "./FormRow";

const DynamicForm = ({ onChange, formData, formRows, formFields, ...rest }) => {
  return (
    <Form modal {...rest}>
      {Array.isArray(formFields) && formFields.length > 0 ? (
        <>
          {formFields.map((formField, idx) => (
            <FieldSet {...formField} key={idx}>
              {formField.formRows?.map((formRow, rowIdx) => (
                <FormRow
                  key={`${idx}_${rowIdx}`}
                  onChange={onChange}
                  {...formRow}
                />
              ))}
            </FieldSet>
          ))}
        </>
      ) : (
        <>
          {formRows?.map((formRow, rowIdx) => (
            <FormRow key={rowIdx} onChange={onChange} formData={formData} {...formRow} />
          ))}
        </>
      )}
    </Form>
  );
};

export default DynamicForm;
