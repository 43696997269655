import Input from "./Input";
import TextFilter from "../../components/TextFilter";
import classNames from "classnames";
import styles from "./listSelector.module.css";

const ListSelector = ({
  listAttributes,
  filteredOptions,
  filterAttribs,
  ...rest
}) => {
  return (
    <div {...rest}>
      <div className={classNames(styles.selectionList, styles.selected)}>
        {listAttributes?.value.map((item) => (
          <Input
            key={item.id}
            type="checkbox"
            label={item.label}
            value={true}
            onClick={() => {
              listAttributes.onChange(item);
            }}
          />
        ))}
      </div>
      <TextFilter {...filterAttribs} />
      <div className={classNames(styles.selectionList, styles.options)}>
        {filteredOptions?.map((option) => (
          <Input
            key={option.id}
            type="checkbox"
            label={option.label}
            value={false}
            onClick={() => {
              listAttributes.onChange(option);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ListSelector;

/*
        <div className={styles.dropdownContainer}>
          {selectorOptions.map((option) => (
            <div
              key={option?.id}
              onClick={() => {
                onChange(option);
              }}
              className={classNames(styles.selectorOption, {
                [styles.selected]: option?.id === selectedValue?.id,
              })}
            >
              {option?.label}
            </div>
          ))}
        </div>
*/
