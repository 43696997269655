import classNames from "classnames";
import ReactDOM from "react-dom";
import ErrorBoundary from "./ErrorBoundary";
import SideNav from "../features/navigation/SideNav";
import Button from "./Button";
import useAuth from "../features/auth/hooks/useAuth";
import useLang from "../hooks/useLang";
import styles from "./aside.module.css";

const Aside = ({ expanded, onToggle }) => {
  const { getLogoutUrl } = useAuth();
  const lang = useLang();
  return ReactDOM.createPortal(
    <>
      <aside
        className={classNames(styles.aside, {
          [styles.expanded]: expanded,
          [styles.collapsed]: !expanded,
        })}
      >
        <ErrorBoundary fallback="Error side nav">
          <SideNav expanded={expanded} />
          <div className={styles.buttonGroup}>
            <Button sideNav to={getLogoutUrl()} icon="exit">
              {expanded ? lang("auth.logout") : null}
            </Button>
            <Button
              onClick={onToggle}
              icon={expanded ? "collapseLeft" : "collapseRight"}
            />
          </div>
        </ErrorBoundary>
      </aside>
    </>,
    document.getElementById("sideNav")
  );
};

export default Aside;
