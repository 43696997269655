import getItemsToAdd from "../../../utils/getItemsToAdd";
import useTextFilter from "../../../hooks/useTextFilter";
import useFormMultiselector from "./useFormMultiselector";

const useFormListSelector = ({
  id,
  initialValue,
  options,
  onChange: handleChange,
}) => {
  const listAttributes = useFormMultiselector({
    id,
    initialValue,
    options,
    onChange: handleChange,
  });
  const { filteredData: filteredOptions, ...filterAttribs } = useTextFilter({
    data: getItemsToAdd(listAttributes.value, options),
    id: "label",
  });

  return {
    listAttributes: { ...listAttributes },
    filterAttribs,
    filteredOptions,
  };
};

export default useFormListSelector;
