import Button from "./Button";
import Popup from "./Popup";

const TableHeaderButton = ({
  onClick,
  icon,
  active,
  sort,
  filter,
  options,
  ...rest
}) => {
  if (sort)
    return (
      <Button inline deemphasized={!active} icon={icon} onClick={onClick} />
    );
  if (filter)
    return (
      <Popup
        dropdown
        expandBottomRight
        fitContent
        renderOpen={(onClick) => (
          <Button inline deemphasized={!active} icon={icon} onClick={onClick} />
        )}
      >
        {Array.isArray(options) &&
          options.map((option, idx) => (
            <Button
              key={option}
              inline
              onClick={() => {
                onClick(idx !== 0 ? option : null);
              }}
            >
              {option}
            </Button>
          ))}
      </Popup>
    );
  return <Button inline deemphasized={!active} icon={icon} onClick={onClick} />;
};

export default TableHeaderButton;

/*
const DropdownSelector = ({
  options,
  value,
  defaultValue,
  onChange,
  label,
  noEmpty,
  withFilter,
}) => {


        <Popup
        keepOpen
        dropdown
        expandBottomRight
        renderOpen={(onClick) => (
          <div
            onClick={onClick}
            className={styles.dropdownSelector}
            style={{
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <p
              style={{
                whiteSpace: "noWrap",
                overflow: "hidden",
                width: "100%",
                textOverflow: "ellipsis",
              }}
            >
              {selectedValue?.label || "-"}
            </p>
            <Icon chevronDown />
          </div>
        )}
      >
        {withFilter ? (
          <div
            onClick={(event) => {
              event.preventDefault();
            }}
            className={styles.dropdownFilter}
          >
            <TextFilter {...filterAttribs} />
          </div>
        ) : null}
        <div className={styles.dropdownContainer}>
          {selectorOptions.map((option) => (
            <div
              key={option?.id}
              onClick={() => {
                onChange(option);
              }}
              className={classNames(styles.selectorOption, {
                [styles.selected]: option?.id === selectedValue?.id,
              })}
            >
              {option?.label}
            </div>
          ))}
        </div>
      </Popup>
*/
