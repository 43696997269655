const useUserBlock = () => {
  const items = [
    { id: "given_name", value: "", required: true },
    { id: "family_name", value: "", required: true },
    { id: "primary_email", value: "", required: true },
    {
      id: "roles",
      options: ["ADMIN", "EXPERT"],
      type: "chip-multiselector",
      required: true,
    },
  ];

  return items;
};

export default useUserBlock;
