import { apiSlice } from "../api/apiSlice";
import getPaginationInfo from "../../utils/getPaginationInfo";
import paramConfig from "../../config/paramConfig";

export const featuresSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeatures: builder.query({
      query: ({ page = 1, pageSize = 100 }) =>
        `/features?${paramConfig.PAGE_NUMBER}=${page}&${paramConfig.PAGE_SIZE}=${pageSize}`,
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        return { response, pagination };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          data?.response?.map((feature) => {
            let exists = false;
            dispatch(
              apiSlice.util.updateQueryData(
                "getFeature",
                { id: feature?.id },
                () => {
                  exists = true;
                }
              )
            );
            if (!exists) {
              dispatch(
                apiSlice.util.upsertQueryData(
                  "getFeature",
                  { id: feature.id },
                  feature
                )
              );
            }
            return false;
          });
        } catch (err) {
          console.error(err);
        }
      },
      providesTags: (result, error, arg) => [
        { type: "Feature" },
        { type: "Feature", id: arg?.page },
        { type: "Feature", id: arg?.pageSize },
      ],
    }),
    getFeature: builder.query({
      query: ({ id }) => `/features/${id}`,
      providesTags: (result, error, arg) => [{ type: "Feature", id: arg?.id }],
    }),
    postFeature: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/features`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        dispatch(
          apiSlice.util.updateQueryData("getFeatures", undefined, (draft) => {
            draft.push({ ...body, id: `tmp.${Date.now()}` });
          })
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.upsertQueryData(
              "getFeature",
              { id: data?.feature_id },
              body
            )
          );
        } catch (err) {
          apiSlice.util.invalidateTags([{ type: "Feature" }]);
        }
      },
      invalidatesTags: [{ type: "Feature" }],
    }),
    deleteFeature: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/features/${params?.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Feature" }],
    }),
    patchFeature: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/features/${params?.featureId}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Feature" },
        { type: "Feature", id: arg?.params?.featureId },
      ],
    }),
    postConclusionForFeature: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/features/${params?.featureId}/conclusions`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.invalidateTags([
              { type: "Conclusion" },
              { type: "Conclusion", id: body?.conclusion_id },
            ])
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteConclusionForFeature: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/features/${params?.featureId}/conclusions/${params?.conclusionId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted({ params }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.invalidateTags([
              { type: "Conclusion", id: params?.conclusionId },
            ])
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const {
  useGetFeaturesQuery,
  useGetFeatureQuery,
  usePostFeatureMutation,
  useDeleteFeatureMutation,
  usePatchFeatureMutation,
  usePostConclusionForFeatureMutation,
  useDeleteConclusionForFeatureMutation,
} = featuresSlice;
