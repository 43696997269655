import { useState } from "react";

const useModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(state => state !== true ? true : state);
    if (typeof props?.onOpen === "function") props.onOpen();
  };
  const onSubmit = () => {
    setIsOpen(state => state !== false ? false : state);
    if (typeof props?.onSubmit === "function") props.onSubmit();
  };
  const onCancel = () => {
    setIsOpen(state => state !== false ? false : state);
    if (typeof props?.onCancel === "function") props.onCancel();
  };
  const onClose = () => {
    setIsOpen(state => state !== false ? false : state);
    if (typeof props?.onClose === "function") props.onClose();
  };

  return { isOpen, onOpen, onSubmit, onCancel, onClose };
};

export default useModal;
