import { useState } from "react";

const useFormData = (inputs) => {
  const [formData, setFormData] = useState();

  let requiredInputs = [];
  if (inputs && Array.isArray(inputs)) {
    requiredInputs = inputs
      .filter((input) => input.required === true)
      .map((input) => input.id);
  }

  const onChange = ({ id, value, remove }) => {
    setFormData((state) => {
      let newState = state != null ? { ...state } : {};
      if (remove) {
        const keysToRemove = Object.keys(state).filter((key) =>
          key.includes(id)
        );
        keysToRemove.map((keyToRemove) => {
          delete newState[keyToRemove];
          return false;
        });
        return newState;
      }
      newState[id] = value;
      return newState;
    });
  };

  let missingInputs = [];
  if (formData != null && Array.isArray(requiredInputs)) {
    missingInputs = requiredInputs.filter(
      (inputId) =>
        formData[inputId] == null ||
        formData[inputId] === "" ||
        (Array.isArray(formData[inputId]) && formData[inputId].length < 1)
    );
  }

  return { formData, onChange, missingInput: missingInputs[0] };
};

export default useFormData;
